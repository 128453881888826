.clearlyBasics {
  width: 129px;
  height: 20px;
}

.clearlyGamer {
  width: 200px;
  height: 100px;
}

.clearlyJunior {
  width: 125px;
  height: 20px;
}

.derekCardigan {
  width: 200px;
  height: 100px;
}

.mainCentral {
  width: 200px;
  height: 100px;
}

.reincarnate {
  width: 200px;
  height: 100px;
}

.perspective {
  width: 169px;
  height: 20px;
}

.kamDhillon {
  width: 200px;
  height: 100px;
}

.josephMarc {
  width: 200px;
  height: 100px;
}

.oneSight {
  color: transparent;
  width: 200px;
  height: 100px;
}

@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.prescription-validation {
  display: flex;
  align-items: center;
  padding-bottom: 1rem;

  label {
    margin-right: 0.5rem;
  }

  .required {
    color: red;
    border-color: red;
  }
}

.prescriptionTopHeader {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  width: 100%;
  border-bottom: 0.5px solid var(--silver-grey);
  border-right: 0.5px solid var(--silver-grey);
  padding: 1rem;

  @include breakpoints.down(md) {
    grid-template-columns: 1fr;
  }
}

.prescriptionHeaderValue {
  font-weight: bold;
}

@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.badgeWrapper {
  margin-top: 0;
  margin-bottom: func.px-to-rem(2px);
}

.orderInfoContainer {
  width: 100%;
}

.orderInfoLine {
  width: 100%;
  font-size: func.px-to-rem(16px);
  display: flex;
  margin: func.px-to-rem(8px) 0;
  flex-wrap: wrap;

  &Heading {
    font-weight: 700;
    width: 90px;
    display: inline-block;
  }

  &Content {
    font-weight: normal;

    &Bold {
      font-weight: 700;
      font-size: func.px-to-rem(14px);
      align-self: flex-end;
      display: block;
      width: 100%;
      margin-left: 0;
      margin-top: func.px-to-rem(5px);

      @include breakpoints.up(md) {
        margin-left: func.px-to-rem(16px);
        margin-top: auto;
        width: auto;
      }
    }
  }
}

.orderCTAs {
  display: flex;
  margin-top: func.px-to-rem(10px);
  gap: func.px-to-rem(10px);
  width: 100%;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;

  &Crowded {
    flex-wrap: wrap;
  }
  @include breakpoints.up(md) {
    width: auto;
    margin-top: 0;
  }
}

.imageGallery {
  position: relative;
  width: 100%;
  height: func.px-to-rem(95px);
  display: flex;
  flex-wrap: wrap;
  gap: func.px-to-rem(20px);
  padding: func.px-to-rem(1px);
  padding-top: func.px-to-rem(8px);
  overflow: hidden;

  @include breakpoints.up(md) {
    height: func.px-to-rem(131px);
  }

  &Container {
    position: relative;
    border-radius: 8px;
    width: fit-content;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &Overlay {
      position: relative;
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        border-radius: 8px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(91, 93, 97, 0.6);
        z-index: 1;
      }
    }
  }

  &Img {
    height: 85px;
    width: auto;
    box-shadow: 0 0 0 1px func.get-color(alto);
    border-radius: 8px;

    @include breakpoints.up(md) {
      height: 120px;
    }
  }

  &Text {
    position: absolute;
    color: func.get-color(white);
    font-weight: 700;
    font-size: func.px-to-rem(32px);
    z-index: 2;
  }
}

.contactUs {
  display: inline-flex;
  font-size: func.px-to-rem(15px);
  gap: func.px-to-rem(8px);
  font-weight: 700;
  color: func.get-color(black);
  align-items: center;
  margin-left: func.px-to-rem(20px);

  @include breakpoints.up(md) {
    margin-left: func.px-to-rem(40px);
  }

  &Icon {
    width: func.px-to-rem(16px);
    height: func.px-to-rem(16px);
    > svg > path {
      stroke-width: 2px;
      stroke: func.get-color(black);
    }
  }

  &Text {
    text-decoration: underline;
  }
}

.dropDownIcon {
  position: absolute;
  right: 10px;
  top: 8px;
  transform: rotate(0deg); /* Default state */
  transition: all 0.3s;
  width: 1rem;
  height: 1rem;

  &.open {
    transform: rotate(180deg); /* Rotated state */
  }
}

@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints.scss' as breakpoints;

.badgeContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: functions.spacing(11);
  padding-top: functions.spacing(6);

  @include breakpoints.down(391px) {
    padding-bottom: functions.spacing(2);
    padding-top: functions.spacing(4);
  }
}

.primaryBadge {
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.3rem;
  text-transform: uppercase;

  @include breakpoints.up(sm) {
    padding-bottom: functions.spacing(2);
    text-align: center;
  }

  @include breakpoints.down(391px) {
    font-size: 0.625rem;
    line-height: 1rem;
  }
}

.secondaryBadges {
  font-size: 0.625rem;
  line-height: 1.6;

  @include breakpoints.up(sm) {
    text-align: center;
  }
}

@use '@styles/functions' as functions;

.trashBinIcon {
  background-color: var(--wildSand);
  cursor: pointer;
  width: functions.spacing(9);
  height: functions.spacing(9);
  border: functions.spacing(2) solid var(--wildSand);
  border-radius: 100%;
  margin-right: functions.spacing(4);
  margin-left: functions.spacing(3);
}

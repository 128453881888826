@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.downloadBtn {
  display: inline-flex;
  align-items: center;
  gap: func.px-to-rem(8px);
  font-size: func.px-to-rem(12px);
  font-weight: 700;
  background: transparent;
  border: none;
  cursor: pointer;
  margin-bottom: func.px-to-rem(16px);
  padding-left: 0;
  padding-right: 0;

  @include breakpoints.up(md) {
    margin-bottom: 0;
  }

  &Text {
    text-decoration: underline;
  }
}

.shippingSummary {
  &Title {
    text-transform: uppercase;
    margin: 0;
    margin-bottom: func.px-to-rem(10px);
  }
}

.orderTrackerLink{
  font-size: func.px-to-rem(12px);
  font-weight: 700;
  color: func.get-color(black);
  cursor: pointer;
  margin-left: 0.75rem;
  text-decoration: underline;
}
@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.headingContainer {
  text-align: center;
  color: var(--text-primary-dark);
  padding: 0 16px 36px;
  font-size: 1.5rem;

  @include breakpoints.up(md) {
    padding: 36px 56px;
  }
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 24px;
  @include breakpoints.up(md) {
    padding: 0 148px;
  }
}

@use '@styles/mixins/breakpoints' as breakpoints;
@use '@styles/functions' as functions;

.container {
  margin-top: 0;
  margin-bottom: functions.spacing(2);
  min-height: 38px;
  text-align: center;
}

.countdown {
  @include breakpoints.down(md) {
    font-size: 1.5rem;
  }
}

@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints.scss' as breakpoints;

.container {
  color: var(--text-primary-dark);
  display: flex;
  flex-direction: column;
  font-size: 0.75rem;
  font-weight: normal;
  height: 100%;
  position: relative;
  width: 100%;

  @include breakpoints.up(sm) {
    font-size: 1rem;

    &:hover {
      & > div {
        visibility: visible;
      }
    }
  }
}

.innerContainer {
  @include breakpoints.up(sm) {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
}

.imageContainer {
  padding-top: 0;
  max-height: 50%;

  @include breakpoints.up(sm) {
    max-height: unset;
  }

  img {
    height: 100%;
    object-fit: scale-down;
    width: 100%;
  }
}

.imageAnchor {
  justify-content: center;
  width: 100%;

  &::after {
    height: 0;
  }
}

.imageWrapper {
  display: inline-block;
  font-size: 0;
  overflow: hidden;
  position: relative;
  text-align: center;

  @include breakpoints.down(md) {
    height: 100%;
  }

  @include breakpoints.up(md) {
    &.zoomOnHover {
      height: 100%;
      mix-blend-mode: multiply;
      transition: transform 0.5s;
      width: 100%;

      &:hover,
      &:focus {
        transform: scale(1.1);
      }
    }
  }
}

.productDetails {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: functions.spacing(4);

  @include breakpoints.up(xl) {
    gap: functions.spacing(2);
  }
}

.productVariants {
  font-size: 0.875rem;
  text-align: center;
  min-height: 20px;
  z-index: 1;
}

.footer {
  flex-grow: 1;
  color: var(--text-primary-dark);
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > div {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-start;
  }

  & > div:nth-of-type(2) {
    flex: 0;
    justify-content: center;

    @include breakpoints.up(sm) {
      align-items: flex-end;
      flex-direction: column;
    }
  }
}

.productDescription {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  max-width: 280px;
  text-align: center;

  @include breakpoints.up(sm) {
    max-width: 100%;
  }
}

.productName {
  font-size: 0.875rem;
}

.productBrand {
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.priceContainer {
  padding-bottom: 0;
}

@use '@styles/mixins/breakpoints' as breakpoints;

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 32px 16px;
  row-gap: 16px;

  @include breakpoints.up(sm) {
    padding: 32px 64px;
  }

  .title {
    font-size: 20px;
    font-weight: 600;
    color: var(--primary-dark);
  }
}

@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints' as breakpoints;

.container {
  margin-top: functions.spacing(4);
  margin-bottom: functions.spacing(4);
}

.status {
  background: #dddddd;
  padding: functions.spacing(3);

  .title {
    font-weight: 600;
    margin-bottom: functions.spacing(2);
  }

  .message {
    font-size: 0.875rem;
  }
}

.myclaimsContainer {
  display: flex;
  flex-direction: column;
  padding: functions.spacing(6, 4);
  row-gap: functions.spacing(6);

  @include breakpoints.up(xs) {
    padding-left: functions.spacing(8);
    padding-right: functions.spacing(8);
    padding-top: functions.spacing(6);
  }

  @include breakpoints.up(1023) {
    padding-left: functions.spacing(16);
    padding-right: functions.spacing(16);
    padding-top: functions.spacing(8);
  }
}

@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.itemUnavailableBanner {
  font-size: func.px-to-rem(15px);
  font-weight: bold;
  color: #bb251a;
  background: func.get-color(light3, red);
  margin: 0;
  padding: func.px-to-rem(5px) func.px-to-rem(12px);
}

.itemUnavailablePrice {
  font-size: func.px-to-rem(15px);
  color: #bb251a;
  font-weight: bold;
  margin: 0;
}

.infoIcon {
  height: 1.5rem;
}

@use '@styles/functions' as functions;

.attributesListContainer {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 10px;
  font-size: 0.75rem;
}

.attributesListItem {
  display: flex;

  :nth-child(1) {
    width: 130px;
    min-width: 130px;
  }
}

.rxLensDetailsTitleWrapper {
  display: flex;
  justify-content: space-between;

  & > * {
    margin: 0;
  }
}

.rxLensPriceContainer {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.rxLensPrices {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 0.5rem;
  & :last-child {
    flex: 0 0 auto;
  }
}

.clSubscribedMsg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightGreen);
  padding: 2px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--green);
  font-size: 0.875rem;
  font-weight: 600;
}

@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.dialogContainer {
  background-color: var(--background-light-primary);
  padding: 16px;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: center;
    }
  }

  @include breakpoints.up(sm) {
    min-width: 400px;
    border: 1px solid var(--background-tertiary-light);
  }
}

.subscriptionErrorBox {
  padding: 8px;
  margin-top: 4px;
  font-size: 1rem;
  color: var(--error);
  background-color: var(--lightRed);

  div:nth-child(2) {
    font-weight: 600;
  }
}

.cartActiveSubscriptionSection {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: var(--wildSand);
  min-height: 80px;
  padding: 8px 16px;
  font-size: 0.875rem;

  &.summarySection {
    min-height: 64px;
    margin: 20px 0;
  }

  label span {
    font-weight: bold;
  }

  span:first-of-type {
    font-weight: bold;
  }
  div:nth-of-type(2) {
    display: flex;
    align-items: center;
  }
}

.clSubscribedMsg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightGreen);
  padding: 2px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--green);
  font-size: 0.875rem;
  font-weight: 600;
}

.subscriptionDiscountSection {
  display: flex;
  justify-content: space-between;
  font-size: 0.875rem;
  margin: 8px 0;
  font-weight: 400;

  div:first-of-type {
    font-weight: 600;
  }
}

.switchContainer {
  display: flex;
  align-items: center;
  height: 20px;
  margin-top: 8px;
  > label {
    margin-right: 100;
  }
}

.recurrencyContainer {
  min-height: 44px;
  > div {
    margin-top: -12px;
  }
}

.subscriptionIntervalSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  font-weight: normal;
  margin-top: 0;
  gap: 16px;

  > div:first-of-type {
    margin-top: 24px;
  }

  div:nth-of-type(2) {
    @include breakpoints.up(sm) {
      width: auto;
      margin-top: 24px;
    }
  }

  .recurrency-selector {
    min-width: 200px;
    max-width: 200px;
    margin: 16px 0;
    height: 32px;

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      pointer-events: none;
    }
  }

  .MuiSelect-icon {
    width: unset;
    height: unset;
  }

  &.showStacked {
    flex-direction: column;
    align-items: flex-start;
    font-weight: 600;

    gap: 0;

    div:nth-of-type(2) {
      width: 100%;
      margin-top: 24px;
    }

    .recurrency-selector {
      min-width: 100%;
      max-width: 100%;
    }

    .textfield-wrapper {
      width: 100%;
    }
  }
  @include breakpoints.down(sm) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0;
  }
}

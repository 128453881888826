@use '@styles/functions' as functions;
@use '@styles/mixins/_breakpoints' as breakpoints;

/*
NOTE: this is default styling for CMS carousel.
*/

.wrapper {
  padding: functions.spacing(16, 0);
  display: flex;
  flex-direction: column;
  gap: functions.spacing(8);
  align-items: center;
  color: var(--text-primary-dark);
  line-height: 1.4;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;

  @include breakpoints.up(md) {
    padding: functions.spacing(16, 4);
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: functions.spacing(4);
  text-align: center;
}

.title {
  color: var(--text-primary-dark);
  font-weight: 600;
  line-height: 1.33;
  margin: 0;
}

.subtitle {
  color: var(--text-primary-dark);
  font-weight: 600;
  line-height: 1.33;
  margin: 0;
}

.description {
  font-size: 1rem;
  color: var(--text-primary-dark);
}

.sliderContainer {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sliderInactive {
  @include breakpoints.up(md) {
    :global(.swiper-wrapper) {
      justify-content: center;
    }
  }
}

.navigationContainer {
  align-items: center;
  background-color: var(--white);
  border-radius: 100%;
  box-shadow:
    0px 0px 2.94545px rgba(40, 41, 61, 0.08),
    0px 1.47273px 5.89091px rgba(96, 97, 112, 0.16);
  display: none;
  height: 32px;
  justify-content: center;
  margin-bottom: functions.spacing(12.5);
  width: 32px;
  z-index: 2;

  @include breakpoints.up(xs) {
    display: flex;
  }
}

// TODO: refactor?
.navigationButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  box-sizing: border-box;
  background-color: transparent;
  outline: 0;
  border: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  flex: 0 0 auto;
  font-size: 1.5rem;
  border-radius: 50%;
  overflow: visible;
  color: rgba(0, 0, 0, 0.54);
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: auto;

  &:hover {
    background-color: transparent;
  }
}

.singleItemContainer {
  display: flex;
  justify-content: center;
  max-width: 304px;
  width: 100%;

  @include breakpoints.up(xs) {
    width: unset;
    flex-grow: 0.3;
  }
}

.cmsSwiper {
  justify-content: center;
  width: 100%;

  @include breakpoints.up(md) {
    width: calc(100% - 64px);
  }

  :global(.swiper-slide) {
    display: flex;
    height: auto;
    justify-content: center;
    margin-bottom: functions.spacing(5);
  }

  :global(.swiper-pagination) {
    left: 0;
    margin: functions.spacing(10, auto, 0);
    right: 0;
    width: calc(100% - 64px);
  }

  // NOTE: using :not(.c) to increase specificity and avoid using !important
  :global(.swiper-pagination-bullet):not(.c) {
    margin: functions.spacing(0, 2, 1);
    height: functions.px-to-rem(4px);
    width: functions.px-to-rem(24px);
  }

  :global(.swiper-pagination-bullet-active):not(.c) {
    width: functions.px-to-rem(57px);

    @include breakpoints.down(767px) {
      width: functions.px-to-rem(24px);
    }
  }
}

@use 'src/styles/_variables.scss' as variables;

.video-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  video {
    width: 100%;

    &:global.is-video-clickable {
      z-index: variables.$z-index-lv1;
    }
  }

  &:global.has-preview-picture {
    :global(.is-video-clickable) {
      opacity: 0;
    }
  }

  &:global.is-playing {
    :global(.is-video-clickable) {
      opacity: 1;
    }
  }

  :global(.video-controls) {
    svg path {
      fill: var(--text-primary-light);
    }
  }

  :global(.video-controls-btn) {
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 50px;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    gap: 1rem;
    backdrop-filter: blur(16px);
    background-color: rgba(31, 31, 36, 0.4);
    z-index: variables.$z-index-lv1;

    button {
      display: flex;
      width: max-content;
      height: max-content;
      background: transparent;
      border: none;
      appearance: none;
      outline: none;
      padding: 0;
      margin: 0;

      svg {
        position: relative;
        top: unset;
        left: unset;
        transform: unset;
        width: 17px;
        height: 17px;
        color: var(--primary-light);
      }
    }
  }

  :global(.media-wrapper) & {
    &:global(.is-playing) {
      ~ picture,
      ~ img {
        display: none;
      }

      ~ * {
        picture,
        img {
          display: none;
        }
      }
    }
  }
}

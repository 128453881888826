@use '@styles/functions' as functions;
@use '@styles/mixins/breakpoints' as breakpoints;

.cartRecapOutOfStockWrapper {
  opacity: 0.5;
  pointer-events: none;
}

.cartRecapQuantityError {
  color: var(--error);
  font-size: 0.875rem;
  margin: functions.spacing(0, 0, 4, 4);
}

.clSubscribedMsg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--lightGreen);
  padding: 2px 16px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: var(--green);
  font-size: 0.875rem;
  font-weight: 600;
}

.wrapper {
  margin-bottom: 20px;

  :global(.mmWrapping) {
    height: 20px;
  }

  a {
    color: #333;
    text-decoration: none;
    transition: all 0.2s ease-out;
    background-color: transparent;

    &:hover {
      color: #555;
      text-decoration: underline;
    }
  }
}
@use '@styles/_variables.scss' as variables;
@use '@styles/mixins' as mxs;
@use '@styles/functions/_spacing.scss' as s;
@use '@styles/mixins/_breakpoints.scss' as breakpoints;

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.unmounting {
  animation: fadeout ease-out 225ms;
  animation-fill-mode: forwards;
}

.mounting {
  animation: fadein linear 225ms;
}
@include mxs.setElementPosition($selector: '.overlay ', $top: 0, $left: 0) {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: variables.$z-index-lv5;
}

.wrapper {
  opacity: 1;
  transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}
.modal {
  max-width: 576px;
  padding: s.spacing(10, 8, 5, 8);
  position: relative;
  border-radius: s.spacing-value(1);
  background-color: var(--white);
  overflow-y: auto;
  max-height: 100%;
  @include breakpoints.between(sm, md) {
    margin: 0;
    max-height: 100%;
    max-width: 100%;
    width: 100%;
    height: 100%;
  }

  @include mxs.setElementPosition($selector: '.close', $top: s.spacing-value(6), $right: s.spacing-value(6)) {
    cursor: pointer;
    border: none;
    background-color: inherit;
  }

  .title {
    text-align: center;
    font-size: s.spacing-value(4);
    font-weight: var(--font-weight-bold);
    margin-bottom: s.spacing-value(4);
  }

  .body {
    p,
    li {
      font-size: s.spacing-value(2.5);
      line-height: s.spacing-value(6);
      margin-bottom: s.spacing-value(2);
    }

    ol {
      padding-left: s.spacing-value(6);
    }
  }
  .button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: s.spacing-value(1);
    align-items: center;
  }
}

@use '@styles/functions' as functions;

@mixin AnchorPrimary {
  color: var(--blue);
  position: relative;
  text-decoration: none;

  &::after {
    background: var(--blue);
    bottom: 1px;
    content: '';
    left: 0;
    height: 1px;
    position: absolute;
    transition: 200ms;
    visibility: visible;
    width: 100%;
  }

  &:visited::after {
    background: var(--purple);
    bottom: 1px;
    content: '';
    left: 0;
    height: 1px;
    position: absolute;
    transition: 200ms;
    visibility: visible;
    width: 100%;
  }

  &:hover::after {
    width: 0;
    visibility: hidden;
  }

  &:hover {
    color: var(--text-primary-dark);
    cursor: pointer;
  }

  &:visited {
    color: var(--purple);
  }

  &:visited:hover {
    color: var(--text-primary-dark);
  }

  &:active {
    color: var(--text-primary-dark);
  }
}

.primary {
  @include AnchorPrimary;
}

.black {
  @include AnchorPrimary;
  color: var(--text-primary-dark);

  &::after {
    background: var(--background-primary-dark);
  }

  &:visited::after {
    background: var(--background-primary-dark);
  }

  &:visited {
    color: var(--text-primary-dark);
  }

  &:visited:hover {
    color: var(--text-primary-dark);
  }

  &:active {
    color: var(--text-primary-dark);
  }
}

.wrapper {
  font-size: 0;
}

.noAnimation {
  color: var(--cyprus);
  font-size: 0.875rem;
  position: unset;
  text-decoration: none;

  &::after {
    visibility: hidden;
  }

  &:hover::after {
    visibility: hidden;
  }
}

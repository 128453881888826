@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.mainContainer {
  margin-bottom: func.spacing(1);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @include breakpoints.down(sm) {
    height: 100%;
  }
}

.imageContainer {
  width: 100%;
  margin-bottom: func.spacing(6);
  @include breakpoints.up(lg) {
    margin-bottom: func.spacing(3);
  }
}

.columnContainer {
  width: 100%;
  margin-bottom: func.spacing(6);
  .title {
    display: block;
    font-weight: 700;
    margin-top: func.spacing(6);
    margin-bottom: func.spacing(2);
  }
  @include breakpoints.up(md) {
    width: calc(100% - 20px);
    margin-bottom: 0;
  }

  &.twoColumns {
    width: calc(50% - 20px);
  }
}

.gapColumnContainer {
  @include breakpoints.up(lg) {
    display: none;
  }
}

.featureItemTitle {
  font-weight: 700;
  margin: func.spacing(1, 0, 0, 1);
  color: #000;
  font-size: 1rem;
  margin-left: func.spacing(3);
}

.featureItemDescription {
  margin: func.spacing(1, 0, 0, 1);
  font-size: 1rem;
  line-height: 1.2;
  color: var(text-primary-dark);
  font-weight: 400;
  margin-bottom: func.spacing(2);
  margin-left: func.spacing(3);
}

.itemTitle {
  font-weight: 700;
  margin-top: func.spacing(6);
  font-size: 1rem;
  color: var(text-primary-dark);
}

.itemDescription {
  font-weight: 400;
  margin-top: func.spacing(6);
  font-size: 1rem;
  color: var(text-primary-dark);
}

.carouselContainer {
  margin-bottom: func.spacing(2);
}

.carouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  background: var(--porcelain);
}

.featureItemSection {
  display: flex;
  width: 100%;
  font-size: 1rem;

  .icon {
    width: func.spacing(9);
  }
  .body {
    margin-left: func.spacing(2);
  }
}

.bodySection {
  max-height: 530px;
  overflow-y: auto;
}

@use '@styles/functions' as func;
@use '@styles/mixins/breakpoints' as breakpoints;

.headingContainer {
  text-align: center;
  color: var(--text-primary-dark);
  padding: 8px;
  font-size: 1.5rem;

  @include breakpoints.up(md) {
    margin-top: 20px;
    padding: 16px;
  }
}

.warningSection {
  text-align: center;
  margin-top: 16px;
  padding: 16px 24px 0 12px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  @include breakpoints.up(md) {
    flex-direction: row;
  }
}

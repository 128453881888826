@use '@styles/mixins/breakpoints' as breakpoints;

.mainContainer {
  position: relative;
}

.mediaOverlay {
  position: absolute;
  color: var(--white);
  font-size: 0.75rem;
  font-weight: 700;

  &.title {
    display: flex;
    width: 100%;
    justify-content: center;
    bottom: 10%;
    @include breakpoints.down(sm) {
      bottom: 1%;
    }
  }

  &.items {
    width: 100%;
    bottom: 20%;
    display: flex;
    justify-content: space-around;
    div: {
      width: 50%;
      display: flex;
      justify-content: center;
    }

    @include breakpoints.down(sm) {
      bottom: 15%;
    }
  }
}

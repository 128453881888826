@use '@styles/functions' as functions;

.paymentIcon {
  width: 48px;
  height: 32px;
}

.productTileContainer {
  background-color: white;
  display: flex;
  align-items: center;
  margin-bottom: functions.spacing(10);
  max-width: 416px;
}

.lightColor {
  color: white;
}

// TODO: remove important when refactoring this component to not use MUI
.valid {
  color: var(--greenPea) !important;
}

// TODO: remove important when refactoring this component to not use MUI
.alert {
  color: var(--thunderBird) !important;
}

.clearButton {
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.arrowDown {
  margin-right: 10px;
  width: 14px;
  height: 14px;
}

@mixin ButtonPrimaryContainedLight {
  color: var(--black);
  background-color: var(--white);
  border-color: var(--white);
}

@mixin ButtonPrimaryContainedDark {
  color: var(--white);
  background-color: var(--black);
  border-color: var(--black);
}

@mixin ButtonPrimaryContainedLightHover {
  background-color: var(--wildSand);
  border-color: var(--white);
  color: var(--black);
}

@mixin ButtonPrimaryContainedDarkHover {
  background-color: var(--grey);
  border-color: var(--button-contained-primary-border-hover);
}

@mixin ButtonPrimaryOutlinedLight {
  color: var(--white);
  background-color: transparent;
  border-color: var(--white);
}

@mixin ButtonPrimaryOutlinedDark {
  color: var(--black);
  background-color: transparent;
  border-color: var(--button-outlined-primary-border);
}

@mixin ButtonPrimaryOutlinedLightHover {
  opacity: 0.9;
}

@mixin ButtonPrimaryOutlinedDarkHover {
  background-color: rgba(128, 128, 128, 0.5);
}

@mixin ButtonSecondaryContained {
  color: var(--button-contained-secondary-color);
  background-color: var(--button-contained-secondary-background);
  border-color: var(--button-contained-secondary-border);
}

@mixin ButtonSecondaryContainedHover {
  background-color: var(--button-contained-secondary-background-hover);
  border-color: var(--button-contained-secondary-border-hover);
}

@mixin ButtonSecondaryOutlined {
  color: var(--button-outlined-secondary-color);
  background-color: transparent;
  border-color: var(--button-outlined-secondary-border);
}

@mixin ButtonSecondaryOutlinedHover {
  opacity: 0.8;
}

@mixin ButtonTertiaryContained {
  color: var(--button-contained-tertiary-color);
  background-color: var(--button-contained-tertiary-background);
  border-color: var(--button-contained-tertiary-border);
}

@mixin ButtonTertiaryContainedHover {
  background-color: var(--button-contained-tertiary-background-hover);
  border-color: var(--button-contained-tertiary-border-hover);
}

@mixin Button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  appearance: none;
  font-family: var(--font-family);
  font-size: 1rem;
  line-height: 1.25;
  cursor: pointer;
  text-decoration: none;
  min-width: auto;
  background: none;
  padding: 10px 24px;
  letter-spacing: normal;
  border: 1px solid var(--primary-dark);
  border-radius: 4px;
  background: inherit;
  color: var(--secondary-dark);
  background-color: var(--primary-dark);
  text-wrap: nowrap;
  gap: 0.5rem;

  &.big {
    height: 48px;
  }

  &.primary {
    &.contained-light {
      @include ButtonPrimaryContainedLight;

      &:hover {
        @include ButtonPrimaryContainedLightHover;
      }
    }
    &.contained-dark {
      @include ButtonPrimaryContainedDark;

      &:hover {
        @include ButtonPrimaryContainedDarkHover;
      }
    }

    &.outlined-light {
      @include ButtonPrimaryOutlinedLight;

      &:hover {
        @include ButtonPrimaryOutlinedLightHover;
      }
    }

    &.outlined-dark {
      @include ButtonPrimaryOutlinedDark;

      &:hover {
        @include ButtonPrimaryOutlinedDarkHover;
      }
    }

    &.big {
      background-color: var(--success);
      border: 1px solid var(--success);

      &:hover {
        background-color: var(--successDark);
      }
    }
  }

  &.secondary {
    &.contained {
      @include ButtonSecondaryContained;

      &:hover {
        @include ButtonSecondaryContainedHover;
      }
    }

    &.outlined {
      @include ButtonSecondaryOutlined;

      &:hover {
        @include ButtonSecondaryOutlinedHover;
      }
    }
  }

  &.tertiary {
    &.contained {
      @include ButtonTertiaryContained;

      &:hover {
        @include ButtonTertiaryContainedHover;
      }
    }
  }

  &.disabled,
  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }
}

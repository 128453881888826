.container {
  width: auto;
}

.countdown {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.25;
  margin: 0;
}

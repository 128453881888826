@use '@styles/mixins/breakpoints' as breakpoints;

.mt {
  margin-top: 0.5rem;
}

.container {
  display: flex;
  gap: 0.5rem;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

.description {
  display: flex;
  gap: 8px;
  align-items: center;
  margin: 0;
  margin-bottom: 1rem;
}

.formFields {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  @include breakpoints.down(md) {
    flex-direction: column;
  }
}

.column {
  flex-direction: column;
  gap: 2rem;
}

.row {
  flex-direction: row;

  @include breakpoints.down(md) {
    flex-direction: column;
  }
}

.inputField {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex: 1;

  :global(.MuiFormHelperText-root.Mui-error) {
    position: relative;
    top: 0;
  }
}

.inputField .disabledField [class*='Mui-disabled'],
.inputField .disabledField input {
  background: var(--porcelain);
}

.inputTooltip {
  transform: translateY(1rem);
}

.ackowledgementField:global(.MuiFormControlLabel-root) {
  margin: 0;
}

.acknowledgementLabel {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  flex: 1;
}

.container .confirmationError:global(.MuiFormHelperText-root.Mui-error) {
  position: relative;
  bottom: 0;
  margin-inline: 5px;
}
